<template>
  <div class="crm f-flex">
    <div class="crm-name">協議規則</div>
  </div>

  <div class="ruleMainDv" v-if="currentGame.code < 20000">
    <div class="ruleDv">
      <div class="bTitle" style="padding: 20px 0 0 0">協 議</div>
      <ul class="ruleUl">
        <li>
          ( 1 )&nbsp;&nbsp; 為避免出現爭議，請您務必在下註之後查看「投註明細」。
        </li>
        <li>
          ( 2 )&nbsp;&nbsp;
          任何投訴必須在開獎之前，公司將不接受任何開獎之後的投訴。
        </li>
        <li>
          ( 3 )&nbsp;&nbsp;
          公佈賠率時出現的任何打字錯誤或非故意人為失誤，所有（相關）註單一律不算。
        </li>
        <li>
          ( 4 )&nbsp;&nbsp;
          公佈之所有賠率為浮動賠率，下註時請確認當前賠率及金額，下註確認後一律不能修改。
        </li>
        <li>( 5 )&nbsp;&nbsp; 開獎後接受的投註，一律視為無效。</li>
        <li>
          ( 6 )&nbsp;&nbsp;
          若本公司發現客戶以不正當的手法投註或投註註單不正常，公司將有權「取消」相應之註單，客戶不得有任何異議。
        </li>
        <li>
          ( 7 )&nbsp;&nbsp;
          如因軟件或線路問題導致交易內容或其他與賬號設定不符合的情形，請在開獎前立即與本公司聯絡反映問題，否則本公司將以資料庫中的數據為準。
        </li>
        <li>
          ( 8 )&nbsp;&nbsp;
          倘若發生遭黑客入侵破壞行為或不可抗拒之災害致網站故障或資料損壞、數據丟失等情況，公司將以資料庫數據為依據。
        </li>
        <li>
          ( 9 )&nbsp;&nbsp;
          各級管理人員及客戶必須對本系統各項功能進行了解及熟悉，任何違反正常使用的操作，公司概不負責。
        </li>
        <li>( 10 )&nbsp;請認真了解以下遊戲規則。</li>
        <li>
          ( 11
          )&nbsp;如果會員信用額度超額或者為負數引起的爭議，一律以公司處理為準。
        </li>
        <li>
          ( 12
          )&nbsp;客戶有責任確保自己的賬戶及密碼保密，如果客戶懷疑自己的資料被盜用，應立即通知本公司，並更改其個人詳細資料。
        </li>
        <li>
          ( 13
          )&nbsp;當退水換賠率的功能開啟時，為避免出現爭議，請您務必在下註前確認退水是否為您所要的退水設定值。
        </li>
      </ul>
    </div>
    <div class="gRuleDv">
      <div class="bTitle">一、遊戲規則</div>
      <ul class="gRuleUl">
        <li>以下所有投註皆含本金。</li>
        <li>
          <div><b class="sTitle">特別號碼：</b></div>
          <div>澳門六合彩公司當期開出的最後一號碼為特別號碼（或稱特碼）。</div>
        </li>
        <li>
          <div><b class="sTitle">特碼單雙：</b></div>
          <div>特碼為偶數叫特雙，為奇數叫特單，49為和。</div>
        </li>
        <li>
          <div><b class="sTitle">特碼大小：</b></div>
          <div>
            開出之特碼大於或等於25為特碼大，小於或等於24為特碼小，49為和。
          </div>
        </li>
        <li>
          <div><b class="sTitle">特碼波色：</b></div>
          <div>指開出之特碼所屬的顔色。</div>
        </li>
        <li>
          <div><b class="sTitle">特碼合數單雙：</b></div>
          <div>
            合單：指開出特碼的個位數加上十位數之和為奇數；合雙：指開出特碼的個位數加上十位數之和為偶數；號碼49為和局。
          </div>
        </li>
        <li>
          <div><b class="sTitle">特碼合數大小：</b></div>
          <div>
            以特碼個位和十位元數字之和來判斷勝負，和數大於或等於7為大，小於或等於6為小，開出49號為和。
          </div>
        </li>
        <li>
          <div><b class="sTitle"></b></div>
          <div>
            如特碼為25，則特碼為大，為單，為藍波，為合單；如特碼為2，則特碼為小，為雙，為紅波，為合雙；特碼為49時，則大小單雙為和，合數單雙為和，波色為綠波。
          </div>
        </li>
        <li>
          <div><b class="sTitle">特碼尾數大小：</b></div>
          <div>
            開出之特碼尾數大於或等於5為特尾大，小於或等於4為特尾小，49為和；
            如特碼為18：為特尾大，如特碼為41：為特尾小，如特碼為49：為和局。
          </div>
        </li>
        <li>
          <div><b class="sTitle">两面：</b></div>
          <div>指大、小、單、双。</div>
        </li>
        <li>
          <div><b class="sTitle">正碼：</b></div>
          <div>
            六合彩公司當期開出之前6個號碼叫正碼。
            第一時間出來的叫正碼1，依次叫正碼2、正碼3……正碼6，不以大小排序。
          </div>
        </li>
        <li>
          <div><b class="sTitle">總分大小：</b></div>
          <div>
            所有七個開獎號碼的分數總和大於或等於175為總分大；分數總和小於或等於174為總分小。如開獎號碼為01、07、15、29、38、46、24，分數總和是160，則總分小。
          </div>
        </li>
        <li>
          <div><b class="sTitle">總分單雙：</b></div>
          <div>
            所有七個開獎號碼的分數總和是單數叫(總分單)，如分數總和是103、193；分數總和是雙數叫(總分雙)，如分數總和是108、160。
          </div>
        </li>
        <li>
          <div><b class="sTitle">正碼1-6特：</b></div>
          <div>
            正1特、正2特、正3特、正4特、正5特、正6特:指下註的正碼特與開出之正碼其開獎順序及開獎號碼相同，視爲中獎，如第一個正碼開獎為13號，下註第一個正碼特為13則視為中獎，其它號碼視為不中獎。
          </div>
        </li>
        <li>
          <div><b class="sTitle">正碼1-6：</b></div>
          <div>
            正碼1、正碼2、正碼3、正碼4、正碼5、正碼6的大小單雙、波色、合數單雙和特別號碼的大小單雙、波色、合數單雙規則相同，如正碼1為25，則正碼1為大，為單，為藍波；正碼2為2，則正碼2為小，為雙，為紅波；號碼為49時，則大小單雙、合數單雙為和，波色為綠。
          </div>
        </li>
        <li>
          <div><b class="sTitle">波色：</b></div>
          <div>
            指號碼所屬的顔色。<br />
            <table class="gRuleTable" width="100%">
              <tbody>
                <tr>
                  <td class="tTd">紅波</td>
                  <td>
                    <span color="#ff0000">
                      01, 02, 07, 08, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35,
                      40, 45, 46</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="tTd">藍波</td>
                  <td>
                    <span color="#0000ff">
                      03, 04, 09, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42,
                      47, 48</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="tTd">綠波</td>
                  <td>
                    <span color="#008000">
                      05, 06, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43,
                      44, 49</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <div><b class="sTitle">特碼生肖(<font color="red">生肖对应号码自然年会变化,当前仅供参考</font>)：</b></div>
          <div>
            指開出特碼所屬的生肖。<br />
            <table class="gRuleTable" width="100%">
              <tbody>
                <tr>
                  <td class="tTd">鼠</td>
                  <td>02,14,26,38</td>
                  <td class="tTd">牛</td>
                  <td>01,13,25,37,49</td>
                  <td class="tTd">虎</td>
                  <td>12,24,36,48</td>
                  <td class="tTd">兔</td>
                  <td>11,23,35,47</td>
                  <td class="tTd">龙</td>
                  <td>10,22,34,46</td>
                  <td class="tTd">蛇</td>
                  <td>09,21,33,45</td>
                </tr>
                <tr>
                  <td class="tTd">马</td>
                  <td>08,20,32,44</td>
                  <td class="tTd">羊</td>
                  <td>07,19,31,43</td>
                  <td class="tTd">猴</td>
                  <td>06,18,30,42</td>
                  <td class="tTd">鸡</td>
                  <td>05,17,29,41</td>
                  <td class="tTd">狗</td>
                  <td>04,16,28,40</td>
                  <td class="tTd">豬</td>
                  <td>03,15,27,39</td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <div><b class="sTitle">尾數：</b></div>
          <div>
            指7個開獎號碼中含有所屬尾數的一個或多個號碼，不論同尾數的號碼出現一個或多個，派彩只派一次。<br />
            <table class="gRuleTable" width="100%">
              <tbody>
                <tr>
                  <td class="tTd">1尾</td>
                  <td>01,11,21,31,41</td>
                  <td class="tTd">2尾</td>
                  <td>02,12,22,32,42</td>
                  <td class="tTd">3尾</td>
                  <td>03,13,23,33,43</td>
                  <td class="tTd">4尾</td>
                  <td>04,14,24,34,44</td>
                  <td class="tTd">5尾</td>
                  <td>05,15,25,35,45</td>
                </tr>
                <tr>
                  <td class="tTd">6尾</td>
                  <td>06,16,26,36,46</td>
                  <td class="tTd">7尾</td>
                  <td>07,17,27,37,47</td>
                  <td class="tTd">8尾</td>
                  <td>08,18,28,38,48</td>
                  <td class="tTd">9尾</td>
                  <td>09,19,29,39,49</td>
                  <td class="tTd">0尾</td>
                  <td>10,20,30,40</td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <div><b class="sTitle">半波：</b></div>
          <div>
            以特碼色波和特碼單雙大小為一個投註組合，當開出特碼符合投註組合，即視爲中獎。若開出特碼為49號時，所有投註半波任一個組合視爲和局，其餘情況視爲不中獎
          </div>
        </li>
        <li>
          <div><b class="sTitle">六肖：</b></div>
          <div>
            挑選6個生肖為一投註組合，投註類別可選擇該投註組合為中或不中。當期開出特碼符合所選生肖及投註類別，即視為中獎。號碼49為和局。
          </div>
        </li>
        <li>
          <div><b class="sTitle">一肖：</b></div>
          <div>
            指開出的7個開獎號碼中含有投註所屬生肖的一個或多個號碼，即視爲中獎，不論同生肖的號碼出現一個或多個，派彩只派一次。
          </div>
        </li>
        <li>
          <div><b class="sTitle">連碼：</b></div>
          <div>
            每個號碼都有自己的賠率，下註組合的總賠率，取該組合號碼的最低賠率為總賠率。<br />
            <table class="gRuleTable" width="100%">
              <tbody>
                <tr>
                  <td width="60" class="tTd">三中二</td>
                  <td>
                    所投註的每三個號碼為一組合，若其中2個是開獎號碼中的正碼，視為中獎，叫三中二；若3個都是開獎號碼中的正碼，叫三中二之中三，其餘情形視為不中獎，如03、04、05為一組合，開獎號碼中有03、04兩個正碼，沒有05，叫三中二，按三中二賠付；如開獎號碼中有03、04、05三個正碼，叫三中二之中三，按中三賠付；如出現1個或沒有，視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td class="tTd">三全中</td>
                  <td>
                    每三個號碼為一組合，若三個號碼都是開獎號碼之正碼，視為中獎，其餘情形視為不中獎。如03、04、05三個都是開獎號碼之正碼，視為中獎，如兩個正碼加上一個特別號碼視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td class="tTd">二全中</td>
                  <td>
                    每二個號碼為一組合，二個號碼都是開獎號碼之正碼，視為中獎，其餘情形視為不中獎（含一個正碼加一個特別號碼之情形）。
                  </td>
                </tr>
                <tr>
                  <td class="tTd">二中特</td>
                  <td>
                    每二個號碼為一組合，二個號碼都是開獎號碼之正碼，叫二中特之二中；若其中一個是正碼，一個是特別號碼，叫二中特之中特；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td class="tTd">特串</td>
                  <td>
                    每二個號碼為一組合，其中一個是正碼，一個是特別號碼，視為中獎，其餘情形視為不中獎（含二個號碼都是正碼之情形）。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <div><b class="sTitle">不中：</b></div>
          <div>
            每個號碼都有自己的賠率，下註組合的總賠率，取該組合號碼的最低賠率為總賠率。<br />
            <table class="gRuleTable" width="100%">
              <tbody>
                <tr>
                  <td width="60" class="tTd">五不中</td>
                  <td>
                    挑選5個號碼為一投註組合進行下註。當期開出的7個開獎號碼都沒有在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td width="60" class="tTd">六不中</td>
                  <td>
                    挑選6個號碼為一投註組合進行下註。當期開出的7個開獎號碼都沒有在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td width="60" class="tTd">七不中</td>
                  <td>
                    挑選7個號碼為一投註組合進行下註。當期開出的7個開獎號碼都沒有在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td width="60" class="tTd">八不中</td>
                  <td>
                    挑選8個號碼為一投註組合進行下註。當期開出的7個開獎號碼都沒有在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td width="60" class="tTd">九不中</td>
                  <td>
                    挑選9個號碼為一投註組合進行下註。當期開出的7個開獎號碼都沒有在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td width="60" class="tTd">十不中</td>
                  <td>
                    挑選10個號碼為一投註組合進行下註。當期開出的7個開獎號碼都沒有在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <div><b class="sTitle">生肖連：</b></div>
          <div>
            生肖所對應的號碼和特碼生肖項目的一樣；一個生肖對應多個號碼，不論同生肖的號碼出現一個或多個，派彩只派一次。每個生肖都有自己的賠率，下註組合的總賠率，取該組合生肖的最低賠率為總賠率。<br />
            <table class="gRuleTable" width="100%">
              <tbody>
                <tr>
                  <td width="120" class="tTd">二肖連（ 中 ）</td>
                  <td>
                    選擇二個生肖為一投註組合進行下註。該註的二個生肖必須在當期開出的7個開獎號碼相對應的生肖中，視為中獎。
                  </td>
                </tr>
                <tr>
                  <td width="120" class="tTd">三肖連（ 中 ）</td>
                  <td>
                    選擇三個生肖為一投註組合進行下註。該註的三個生肖必須在當期開出的7個開獎號碼相對應的生肖中，視為中獎。
                  </td>
                </tr>
                <tr>
                  <td width="120" class="tTd">四肖連（ 中 ）</td>
                  <td>
                    選擇四個生肖為一投註組合進行下註。該註的四個生肖必須在當期開出的7個開獎號碼相對應的生肖中，視為中獎。
                  </td>
                </tr>
                <tr>
                  <td width="120" class="tTd">五肖連（ 中 ）</td>
                  <td>
                    選擇五個生肖為一投註組合進行下註。該註的五個生肖必須在當期開出的7個開獎號碼相對應的生肖中，視為中獎。
                  </td>
                </tr>
                <tr>
                  <td width="120" class="tTd">二肖連（不中）</td>
                  <td>
                    選擇二個生肖為一投註組合進行下註。該註的二個生肖必須沒在當期開出的7個開獎號碼相對應的生肖中，視為中獎。
                  </td>
                </tr>
                <tr>
                  <td width="120" class="tTd">三肖連（不中）</td>
                  <td>
                    選擇三個生肖為一投註組合進行下註。該註的三個生肖必須沒在當期開出的7個開獎號碼相對應的生肖中，視為中獎。
                  </td>
                </tr>
                <tr>
                  <td width="120" class="tTd">四肖連（不中）</td>
                  <td>
                    選擇四個生肖為一投註組合進行下註。該註的四個生肖必須沒在當期開出的7個開獎號碼相對應的生肖中，視為中獎。
                  </td>
                </tr>
                <tr>
                  <td width="120" class="tTd">五肖連（不中）</td>
                  <td>
                    選擇五個生肖為一投註組合進行下註。該註的五個生肖必須沒在當期開出的7個開獎號碼相對應的生肖中，視為中獎。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <div><b class="sTitle">尾數連：</b></div>
          <div>
            尾數所對應的號碼和尾數項目的一樣；一個尾數對應多個號碼，不論同尾數的號碼出現一個或多個，派彩只派一次。每個尾數都有自己的賠率，下註組合的總賠率，取該組合尾數的最低賠率為總賠率。<br />
            <table class="gRuleTable" width="100%">
              <tbody>
                <tr>
                  <td width="120" class="tTd">二尾連（ 中 ）</td>
                  <td>
                    選擇二個尾數為一投註組合進行下註。該註的二個尾數必須在當期開出的7個開獎號碼相對應的尾數中，視為中獎。
                  </td>
                </tr>
                <tr>
                  <td width="120" class="tTd">三尾連（ 中 ）</td>
                  <td>
                    選擇三個尾數為一投註組合進行下註。該註的三個尾數必須在當期開出的7個開獎號碼相對應的尾數中，視為中獎。
                  </td>
                </tr>
                <tr>
                  <td width="120" class="tTd">四尾連（ 中 ）</td>
                  <td>
                    選擇四個尾數為一投註組合進行下註。該註的四個尾數必須在當期開出的7個開獎號碼相對應的尾數中，視為中獎。
                  </td>
                </tr>
                <tr>
                  <td width="120" class="tTd">二尾連（不中）</td>
                  <td>
                    選擇二個尾數為一投註組合進行下註。該註的二個尾數必須沒在當期開出的7個開獎號碼相對應的尾數中，視為中獎。
                  </td>
                </tr>
                <tr>
                  <td width="120" class="tTd">三尾連（不中）</td>
                  <td>
                    選擇三個尾數為一投註組合進行下註。該註的三個尾數必須沒在當期開出的7個開獎號碼相對應的尾數中，視為中獎。
                  </td>
                </tr>
                <tr>
                  <td width="120" class="tTd">四尾連（不中）</td>
                  <td>
                    選擇四個尾數為一投註組合進行下註。該註的四個尾數必須沒在當期開出的7個開獎號碼相對應的尾數中，視為中獎。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <div><b class="sTitle">一肖不中</b></div>
          <div>指開出的7個開獎號碼中不含有投註所屬生肖，即視爲中獎。</div>
        </li>
        <li>
          <div><b class="sTitle">尾數不中：</b></div>
          <div>指開出的7個開獎號碼中不含有投註所屬尾數，即視爲中獎。</div>
        </li>
        <li>
          <div><b class="sTitle">七碼：</b></div>
          <div>
            估猜七個開獎號碼中【單、雙、大、小】各有多少個，例如︰投註【單0】，若該期開出的七個號碼中一個單數號碼都沒出現，即視爲中獎，若七個號碼中有一個或多個單數出現則為不中獎，開出49號算【單、大】。
          </div>
        </li>
        <li>
          <div><b class="sTitle">五行：</b></div>
          <div>
            指開出特碼所屬的五行號碼。<br />
            <table class="gRuleTable" width="100%">
              <tbody>
                <tr>
                  <td width="60" class="tTd">金</td>
                  <td>01,06,11,16,21,26,31,36,41,46</td>
                  <td width="60" class="tTd">木</td>
                  <td>02,07,12,17,22,27,32,37,42,47</td>
                  <td width="60" class="tTd">水</td>
                  <td>03,08,13,18,23,28,33,38,43,48</td>
                </tr>
                <tr>
                  <td width="60" class="tTd">火</td>
                  <td>04,09,14,19,24,29,34,39,44,49</td>
                  <td width="60" class="tTd">土</td>
                  <td>05,10,15,20,25,30,35,40,45</td>
                  <td width="60" class="tTd"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <div><b class="sTitle">中一：</b></div>
          <div>
            每個號碼都有自己的賠率，下註組合的總賠率，取該組合號碼的最低賠率為總賠率。<br />
            <table class="gRuleTable" width="100%">
              <tbody>
                <tr>
                  <td class="tTd">五中一</td>
                  <td>
                    挑選5個號碼為一投註組合進行下註。當期開出的7個開獎號碼只有1個號碼在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td class="tTd">六中一</td>
                  <td>
                    挑選6個號碼為一投註組合進行下註。當期開出的7個開獎號碼只有1個號碼在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td class="tTd">七中一</td>
                  <td>
                    挑選7個號碼為一投註組合進行下註。當期開出的7個開獎號碼只有1個號碼在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td class="tTd">八中一</td>
                  <td>
                    挑選8個號碼為一投註組合進行下註。當期開出的7個開獎號碼只有1個號碼在該下註組合中，即視為中獎；其餘情形視為不中獎
                  </td>
                </tr>
                <tr>
                  <td class="tTd">九中一</td>
                  <td>
                    挑選9個號碼為一投註組合進行下註。當期開出的7個開獎號碼只有1個號碼在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td class="tTd">十中一</td>
                  <td>
                    挑選10個號碼為一投註組合進行下註。當期開出的7個開獎號碼只有1個號碼在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <div><b class="sTitle">特平中：</b></div>
          <div>
            每個號碼都有自己的賠率，下註組合的總賠率，取該組合號碼的最低賠率為總賠率。<br />
            <table class="gRuleTable" width="100%">
              <tbody>
                <tr>
                  <td class="tTd">一粒任中</td>
                  <td>
                    挑選1個號碼為一投註組合進行下註。當期開出的7個開獎號碼有任何1個號碼在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td class="tTd">二粒任中</td>
                  <td>
                    挑選2個號碼為一投註組合進行下註。當期開出的7個開獎號碼有任何1個號碼在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td class="tTd">三粒任中</td>
                  <td>
                    挑選3個號碼為一投註組合進行下註。當期開出的7個開獎號碼有任何1個號碼在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
                <tr>
                  <td class="tTd">四粒任中</td>
                  <td>
                    挑選4個號碼為一投註組合進行下註。當期開出的7個開獎號碼有任何1個號碼在該下註組合中，即視為中獎；其餘情形視為不中獎
                  </td>
                </tr>
                <tr>
                  <td class="tTd">五粒任中</td>
                  <td>
                    挑選5個號碼為一投註組合進行下註。當期開出的7個開獎號碼有任何1個號碼在該下註組合中，即視為中獎；其餘情形視為不中獎。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <div><b class="sTitle">生肖量：</b></div>
          <div>
            當期號碼(所有正碼與最後開出的特碼)開出的不同生肖總數，與所投註之預計開出之生肖總數和(不用指定特定生肖)，則視為中獎，其餘情形視為不中獎。<br />
            例如：如果'當期號碼為19、24、12、34、40、39
            特別號：49，總計六個生肖，若選總肖【6】則為中獎
            (請註意：49號亦算輸贏，不為和)。
          </div>
        </li>
        <li>
          <div><b class="sTitle">尾數量：</b></div>
          <div>
            當期號碼(所有正碼與最後開出的特碼)開出的不同尾數總數，與所投註之預計開出之尾數總數和(不用指定特定尾數)，則視為中獎，其餘情形視為不中獎。<br />
            例如：如果'當期號碼為19、24、12、34、40、39
            特別號：49，總計4個尾數，若選總尾【4】則為中獎
            (請註意：49號亦算輸贏，不為和)。
          </div>
        </li>
      </ul>
    </div>
    <div style="height: 10px"></div>
    <div class="gRuleDv">
      <div class="bTitle">二、下註模式說明</div>
      <ul class="gRuleUl">
        <li>
          <div><b class="sTitle">拖頭：</b></div>
          <div>
            是一種快速下註模式,即選擇好固定號碼（生肖、尾數）為頭（或稱為膽），再選擇其他號碼（生肖、尾數）與其組合進行下註。如三中二之8、18拖19、20、21，即是三組：8-18-19、8-18-20、8-18-21；如二肖連之鼠拖牛、虎，即是2組：鼠-牛、鼠-虎；如二尾連之1拖2、3，即是2組：1-2、1-3。[應用于連碼、生肖連、尾數連等項目]。
          </div>
        </li>
        <li>
          <div><b class="sTitle">複式：</b></div>
          <div>
            是一種快速下註模式，即選擇多個號碼（生肖、尾數）進行排列組合進行下註。[應用于連碼、五不中、生肖連、尾數連等項目]。
          </div>
        </li>
        <li>
          <div><b class="sTitle">多組投註：</b></div>
          <div>
            是一種快速下註模式，可以連續選擇多組號碼進行下註。[應用于五不中等項目]。
          </div>
        </li>
        <li>
          <div><b class="sTitle">生肖對碰：</b></div>
          <div>
            是一種簡易下註模式，生肖都相對應號碼，選擇兩個生肖對碰下註，相應是選擇多個號碼組合成不重復組合進行下註。[應用于連碼等項目]。
          </div>
        </li>
        <li>
          <div><b class="sTitle">尾數對碰：</b></div>
          <div>
            是一種簡易下註模式，尾數都相對應號碼，選擇兩個尾數對碰下註，相應是選擇多個號碼組合成不重復組合進行下註。[應用于連碼等項目]。
          </div>
        </li>
        <li>
          <div><b class="sTitle">生肖尾數對碰：</b></div>
          <div>
            是一種簡易下註模式，生肖和尾數都相對應號碼，選擇生肖尾數對碰下註，相應是選擇多個號碼組合成不重復組合進行下註。[應用于連碼等項目]。
          </div>
        </li>
        <li>
          <div><b class="sTitle">家禽、野獸：</b></div>
          <div>
            是一種快速下註模式，根據十二生肖其動物特性來區分。家禽包括：牛、马、羊、鸡、狗、豬；野獸包括：鼠、虎、兔、龙、蛇、猴。
            [應用于特碼生肖、六肖等項目]。
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="rule-content" v-else>
    <span style="padding-left: 42px; display: block">
      <p style="color:red;font-size:16px;" v-if="currentGame.code == 20003"> 天天3D 每日10:00-21:00可投注,每小时一期，全天共12期。</p>
      
      <p v-else>  3D是依照中国福利彩票管理中心发行的3D的开奖结果为派彩依据，每天开奖一次。</p>
    
      <font color="red"
        >凡未开奖期数，依报表日默认保留至隔日06:30，逾时取消期数、注单，此情况后续处理不另行通知！</font
      >
      <p style="font-weight: bold; color: #212199">
        假设3D开奖结果为123，那么佰位是1，拾位是2，个位是3。（每个位数在开奖时从0~9中摇出一个结果）选号玩法。
      </p>
    </span>
    <ol class="ch">
      <li>
        <p>一字盘势规则说明</p>
        <ol>
          <li>
            <span>一字组合：</span
            >0~9任选1号进行投注，当开奖结果【佰位、拾位、个位】任一数与所选的号码相同时，即为中奖。
          </li>
          <li>
            <span>一字定位：</span
            >于【佰位、拾位、个位】任选一位，自0~9任选1号进行投注，当开奖结果与所选的定位与号码相同且顺序一致时，即为中奖。
          </li>
          <li><span>‘ 佰 / 拾 / 个 ’大小：</span>0~4为小，5~9为大。</li>
          <li>
            <span>‘ 佰 / 拾 / 个 ’单双：</span
            >1、3、5、7、9为单，0、2、4、6、8为双。
          </li>
          <li>
            <span>‘ 佰 / 拾 / 个 ’质合大小单双：</span
            >1、2、3、5、7为质数，0、4、6、8、9为合数
            <br />1、2、3为质小，5、7为质大； <br />0、4为合小，6、8、9为合大；
            <br />1、3、5、7为质单，2为质双； <br />9为合单，0、4、6、8为合双。
          </li>
        </ol>
      </li>
      <li>
        <p>二字盘势规则说明</p>
        <ol>
          <li>
            <span>二字组合：</span
            >0~9任选2号进行投注，当开奖结果【佰位、拾位、个位】任一数与所选的号码相同时，即为中奖。
          </li>
          <li>
            <span>二字定位：</span
            >于【佰位、拾位、个位】任选两位，自0~9任选2号进行投注，当开奖结果与所选的定位与号码相同且顺序一致时，即为中奖。
          </li>
          <li>
            <span>‘ 佰 / 拾 / 个 ’合数单双：</span
            >1、3、5、7、9、11、13、15、17为单，0、2、4、6、8、10、12、14、16、18为双。
          </li>
        </ol>
      </li>
      <li>
        <p>三字盘势规则说明</p>
        <ol>
          <li>
            <span>三字组合：</span
            >自0~9号任选3个皆相同的号码时（如123），当开奖结果【佰位、拾位、个位】与所选号码相同，即为中奖。
          </li>
          <li>
            <span>三字定位：</span
            >于【佰位、拾位、个位】任选三位，自0~9任选3号进行投注，当开奖结果与所选的定位与号码相同且顺序一致时，即为中奖。
          </li>
          <li><span>‘ 佰 / 拾 / 个 ’ 合数大小：</span>0~13为小，14~27为大。</li>
          <li>
            <span>‘ 佰 / 拾 / 个 ’ 合数单双：</span
            >1、3、5、7、9、11、13、15、17、19、21、23、25、27为单，0、2、4、6、8、10、12、14、16、18、20、22、24、26为双。
          </li>
        </ol>
      </li>
      <li>
        <p>组选三规则说明</p>
        <ol>
          <li>
            <span>组选三：</span>
            会员可以挑选5~10个号码，当开奖结果【佰位、拾位、个位】中有且只有两个号码重复，则视为中奖。挑选不同个数的号码有其相对应的赔率。<br />
            如果是选择(1、2、3、4、5)，则只要开奖结果中，有出现1、2、3、4、5中的任何两个号码，且其中有一个号码重复则中奖。<br />
            ※ 例如：112、344，若是开出豹子则不算中奖。<br />
            ※ 备注："豹子"为三字同号，例如：111、222。
          </li>
        </ol>
      </li>
      <li>
        <p>组选六规则说明</p>
        <ol>
          <li>
            <span>组选六：</span
            >会员可以挑选4~8个号码，当开奖结果【佰位、拾位、个位】都出现在所下注的号码中且没有任何号码重复，则视为中奖。<br />
            挑选不同个数的号码有其相对应的赔率，中奖赔率以所选号码中的最小赔率计算派彩。<br />
            ※
            例如：如果是选择(1、2、3、4)，则开奖结果为123、124、134、234都中奖，其他都是不中奖。<br />
            ※ 例如：112、133、145、444等都是不中奖。
          </li>
        </ol>
      </li>
      <li>
        <p>复式组合规则说明</p>
        <ol>
          <li>
            <span>复式组合：</span>
            复式组合
            在佰、拾、个中分别选择号码组合。例如选择3x2x4，即佰位数选3个号码，拾位数选2个号码，个位数选4个号码或选择3x3x3，如每个位数所选择的号码都对应出现在开奖的结果中，则视为中奖。
          </li>
          <li>
            <span>此游戏选号有以下限制：</span>
            <ol>
              <li>每一位数最少要选择1个号码，最多选10个号码。</li>
              <li>
                三个位数共选取号码数量，最少需选择9个号码，最多选21个号码。
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <p>豹子规则说明</p>
        <ol>
          <li>
            <span>豹子：</span>
            中奖号码的【佰位、拾位、个位】数字都相同。<br />
            ※ 例如中奖号码为
            000、111、999等，中奖号码的【佰位、拾位、个位】数字相同，则投注豹子者视为中奖，其它视为不中奖。
          </li>
        </ol>
      </li>
      <li>
        <p>顺子规则说明</p>
        <ol>
          <li>
            <span>顺子：</span>
            中奖号码的【佰位、拾位、个位】数字都相连，不分顺序。（数字9、0、1相连）。<br />
            ※ 例如中奖号码为
            123、234、901等，中奖号码【佰位、拾位、个位】数字相连，则投注顺子者视为中奖，其它视为不中奖。
          </li>
        </ol>
      </li>
      <li>
        <p>对子规则说明</p>
        <ol>
          <li>
            <span>对子：</span>
            中奖号码的【佰位、拾位、个位】任意两位数字相同。（不包括豹子）。<br />
            ※ 例如中奖号码为
            001，112、696，中奖号码有两位数字相同，则投注对子者视为中奖，其它视为不中奖。
          </li>
        </ol>
      </li>
      <li>
        <p>半顺规则说明</p>
        <ol>
          <li>
            <span>半顺：</span>
            中奖号码的【佰位、拾位、个位】任意两位数字相连。（不包括顺子、对子，数字9、0、1相连）。<br />
            ※ 例如中奖号码为
            125、450、903，中奖号码有两位数字相连，则投注半顺者视为中奖，其它视为不中奖。
          </li>
        </ol>
      </li>
      <li>
        <p>杂六规则说明</p>
        <ol>
          <li>
            <span>杂六：</span>
            不包括豹子、对子、顺子、半顺的所有中奖号码。<br />
            ※ 例如中奖号码为
            157，中奖号码位数之间无关联性，则投注杂六者视为中奖，其它视为不中奖。
          </li>
        </ol>
      </li>
      <li>
        <p>准对规则说明</p>
        <ol>
          <li>
            <span>准对：</span>
            从 0~9
            这十个数字中，每注只能选择0~9其中一个号码下注，投注的号码有开出二个以上即中奖（不包含豹子）。<br />
            ※ 例如：下注2号，当开奖号码为 022、202 时，为中奖。开奖号码为 222
            时，不算中奖。
          </li>
        </ol>
      </li>
      <li>
        <p>不出牌规则说明</p>
        <ol>
          <li>
            <span>不出牌：</span>
            从 0~9
            这十个数字中，每注只能选择0~9其中一个号码下注，选择的号码没有开出即算中奖。
          </li>
        </ol>
      </li>
      <li>
        <p>一字过关规则说明</p>
        <ol>
          <li>
            <span>一字过关：</span
            >以开奖三个号码之大小、单双、质合作为中奖的依据。<br />※
            例如：开奖结果为3、4、8。会员若在佰定位下小、拾定位下双、个定位下合。则视为中奖。
          </li>
        </ol>
      </li>
      <li>
        <p>跨度盘势规则说明</p>
        <ol>
          <li>
            <span>跨度盘势：</span>
            以开奖三个号码的最大差距（跨度），作为中奖依据。会员可以选择0~9的任一跨度。<br />
            ※
            例如：开奖结果为3、4、8。中奖的跨度为5。（最大号码8减最小号码3=5）。
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "LhcRule",
  components: {},
  setup() {
    const store = useStore();

    const currentGame = computed(() => {
      return store.state.app.currentGame;
    });
    return {
      currentGame,
    };
  },
};
</script>
<style scoped>
.ch{list-style-type: none;}
</style>